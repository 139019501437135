import * as React from "react";

import { I18nLocaleObject, getI18nLocaleStringFromParams } from "../i18n";

/**
 * Util for obtaining Elements stylings and properties.
 */
export class ElementsUtil {
    /**
     * Provides svg elements for various widgets
     * @param label Text content for svg element
     * @returns JSX element
     */
    public static svgElement(label: I18nLocaleObject | string) {
        return (
            <svg width="100%" height="100%">
                <text fontStyle="italic" fontFamily="Helvetica, Arial, sans-serif" fontSize="16" y="50%" x="50%" dominantBaseline="middle" textAnchor="middle" fill="#999999">
                    {getI18nLocaleStringFromParams(label)}
                </text>
            </svg>
        );
    }
}
