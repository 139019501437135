import * as Icons from "../../page/icons";
import * as React from "react";

import { AccommodationType, getDistributionChannelLazyLoadPage, rateTypeOptions } from "../../mxts";
import { AlternativeColumnOptions, colSelectOptions } from "../../page/columns";
import { CacheStrategy, LocalizedContentBase, Typesearch } from "@maxxton/cms-api";
import { DefaultIcons, IconSelect } from "../../page/assetpublisher";
import { FormSpec, IconColoring, localized, multiSelectStylePicker, templateSpec } from "../../../form-specs";
import { NumberMultiSelectOption, StringMultiSelectOption } from "../../mxts/selectOption.types";
import { PageWidgetSpec, ResultOptions, Widget, isPageWidget, renderPageWidgets, reportWidgetRenderError } from "../../widget";
import { TypesearchContainerWidget, TypesearchContainerWidgetBase } from "./TypesearchContainerWidget";
import { amenityMultiSelect, defaultStayAutoComplete, generateRandomKey, minCapacityField, multiSelectSpecials, resortAutocomplete } from "../../../components/utils";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { ElementsUtil } from "../../../utils/elements.util";
import { MxtsApi } from "@maxxton/cms-mxts-api";
import { ResultStyleSelector } from "../../../utils/constants";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import { isServerSide } from "../../../utils/generic.util";
import { multiSelectAccoKinds } from "../../mxts/typesearch";
import namespaceList from "../../../i18n/namespaceList";
import { regionsAutocomplete } from "../../../inputSpecs";
import { resultsPanelSpec } from "../../../form-specs/models/resultsPanel";
import stayHolidayPeriodDefAutocomplete from "../../../inputSpecs/stayHolidayPeriodDefAutocomplete";
import { webcontentSpecWithoutPreview } from "../../../form-specs/models/autocompleteWebContent";

export interface WidgetOptions extends AlternativeColumnOptions {
    typeOfDisplay: string;
    resultsPanelList: string;
    multipleLayouts: boolean;
    resultsPanelGrid: string;
    resultsPanelTablet: string;
    resultsPanelMobile: string;
    styleIds?: string[];
    showLoadMoreButton: boolean;
    enableVirtualization?: boolean;
    defaultNumberOfTypes: number | undefined;
    nextNumberOfTypes: number | undefined;
    displayType: string;
    webContentId?: string | null;
    templateId?: string | null;
    amenities?: NumberMultiSelectOption[];
    resortId?: string;
    specialCodes?: StringMultiSelectOption[];
    unitPageUri: string;
    localizedOptions?: LocalizeDcOptions[];
    minArrivalDate?: Date;
    maxArrivalDate?: Date;
    sortOnArrivalDate: boolean;
    regionId?: string;
    accoKinds?: NumberMultiSelectOption[];
    defaultStay?: number;
    resultStyleSelector?: string;
    showAmenitiesCount: boolean;
    holidayCode?: string;
    minCapacity?: number;
    displayBookingRestrictedAccoTypes?: boolean;
    localizedBookingIsRestrictedMsg?: LocalizedBookingIsRestrictedMsg[];
    minGuestRating?: string;
    showPriceOnButton?: boolean;
    loadUnits?: boolean;
    buttonLocation?: string;
    enableCount?: boolean;
    unitButtonIcon: boolean;
    iconPropertiesForLess?: string;
    iconPropertiesForMore?: string;
    iconColor: IconColoring;
    iconToRight: boolean;
}
export interface LocalizedBookingIsRestrictedMsg {
    bookingIsRestrictedMsg: string;
    locale: string;
}

interface LocalizeDcOptions extends LocalizedContentBase {
    rateTypes?: NumberMultiSelectOption[];
    distributionChannelId?: number;
    mobileDCId?: number;
    sentenceForShow?: string;
    sentenceForHide?: string;
    sentenceForShowSingular?: string;
    sentenceForHideSingular?: string;
}

const TARGETS = ["price"];

const iconList: IconSelect[] = Object.keys(Icons.default).map((key: string) => ({ label: (Icons.default as DefaultIcons)[key], value: key }));
const i18nDisplayUnitsGroup = getI18nLocaleObject(namespaceList.admin, "displayUnitsGroup");

/* jscpd:ignore-start */
const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "typesearch-price-widget",
    name: getI18nLocaleObject(namespaceList.pluginTypesearchContainer, "typesearchContainerWidget"),
    pluralName: getI18nLocaleObject(namespaceList.pluginTypesearchContainer, "typesearchContainerWidget"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                /* jscpd:ignore-start */
                {
                    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "general"),
                    properties: [
                        [
                            {
                                variable: "resultsPanelList",
                                label: getI18nLocaleObject(namespaceList.dynamicContainer, "resultsPanelList"),
                                type: "autocomplete",
                                refType: resultsPanelSpec,
                            },
                            {
                                variable: "resultsPanelGrid",
                                label: getI18nLocaleObject(namespaceList.dynamicContainer, "resultsPanelGrid"),
                                type: "autocomplete",
                                refType: resultsPanelSpec,
                            },
                            {
                                variable: "multipleLayouts",
                                label: getI18nLocaleObject(namespaceList.displayGridListMapWidget, "multipleLayouts"),
                                type: "checkbox",
                            },
                            {
                                variable: "resultsPanelMobile",
                                label: getI18nLocaleObject(namespaceList.dynamicContainer, "resultsPanelMobile"),
                                type: "autocomplete",
                                refType: resultsPanelSpec,
                                visible: (options: WidgetOptions) => options.multipleLayouts,
                                placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForCRPMobileLayout"),
                            },
                            {
                                variable: "resultsPanelTablet",
                                label: getI18nLocaleObject(namespaceList.dynamicContainer, "resultsPanelTablet"),
                                type: "autocomplete",
                                refType: resultsPanelSpec,
                                visible: (options: WidgetOptions) => options.multipleLayouts,
                                placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForCRPTabletLayout"),
                            },
                            {
                                variable: "unitPageUri",
                                label: getI18nLocaleObject(namespaceList.widgetUnitSearch, "unitBookUri"),
                                type: "text",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "typesGridDisplay"),
                    properties: [
                        [
                            {
                                variable: "columnsResp",
                                type: "select",
                                label: getI18nLocaleObject(namespaceList.admin, "columnsResponsive"),
                                default: "",
                                optionList: colSelectOptions,
                            },
                            {
                                variable: "columns",
                                type: "select",
                                label: getI18nLocaleObject(namespaceList.admin, "columnsMobile"),
                                default: "",
                                optionList: colSelectOptions,
                            },
                            {
                                variable: "columnsTab",
                                type: "select",
                                label: getI18nLocaleObject(namespaceList.admin, "columnsTablet"),
                                default: "",
                                optionList: colSelectOptions,
                            },
                            {
                                variable: "columnsDesk",
                                type: "select",
                                label: getI18nLocaleObject(namespaceList.admin, "columnsDesktop"),
                                default: "",
                                optionList: colSelectOptions,
                            },
                        ],
                    ],
                    visible: (options: WidgetOptions) => (options.resultsPanelGrid ? true : false),
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "display"),
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "descriptionUnitSearchDisplay"),
                            },
                            {
                                variable: "resultStyleSelector",
                                label: getI18nLocaleObject(namespaceList.widgetResultCount, "resultStyleSelector"),
                                type: "radioImage",
                                default: ResultStyleSelector.DEFAULT,
                                radioOptions: [
                                    {
                                        value: ResultStyleSelector.DISPLAY_STYLE_HIDE,
                                        label: getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountHideLabel"),
                                        element: () => ElementsUtil.svgElement(getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountHide")),
                                    },
                                    {
                                        value: ResultStyleSelector.DEFAULT,
                                        label: getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountLabelDefault"),
                                        element: () => ElementsUtil.svgElement(getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountDefault")),
                                    },
                                    {
                                        value: ResultStyleSelector.SHOWING_X_RESULT,
                                        label: getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountLabel1"),
                                        element: () => ElementsUtil.svgElement(getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountOption1")),
                                    },
                                    {
                                        value: ResultStyleSelector.X_RESULT,
                                        label: getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountLabel2"),
                                        element: () => ElementsUtil.svgElement(getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountOption2")),
                                    },
                                ],
                            },
                            {
                                variable: "defaultNumberOfTypes",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "defaultNumberOfTypes"),
                                type: "text",
                                placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForDefaultNumberOfResults"),
                            },
                            {
                                variable: "nextNumberOfTypes",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "nextNumberOfTypes"),
                                type: "text",
                                placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForDefaultNumberOfResults"),
                            },
                            {
                                variable: "showLoadMoreButton",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showLoadMoreButton"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "enableVirtualization",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "enableVirtualization"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "showAmenitiesCount",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showAmenitiesCount"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "loadUnits",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "loadUnits"),
                                type: "checkbox",
                                default: false,
                                groupName: "displayUnitsGroup",
                                groupTitle: i18nDisplayUnitsGroup,
                            },
                            {
                                variable: "buttonLocation",
                                label: getI18nLocaleObject(namespaceList.pluginLocationSearchContainer, "buttonLocation"),
                                type: "select",
                                optionList: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.pluginLocationSearchContainer, "aboveResults"),
                                        value: "above",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.pluginLocationSearchContainer, "belowResults"),
                                        value: "below",
                                    },
                                ],
                                visible: (options: WidgetOptions) => !!options.loadUnits,
                                placeholder: getI18nLocaleObject(namespaceList.amenitiesWidget, "iconSetPlaceholder"),
                                groupName: "displayUnitsGroup",
                            },
                            {
                                variable: "showPriceOnButton",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showPriceOnbutton"),
                                default: false,
                                type: "checkbox",
                                groupName: "displayUnitsGroup",
                            },
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.pluginLocationSearchContainer, "showHideTextInfo"),
                                visible: (options: WidgetOptions) => !!options.loadUnits,
                                groupName: "displayUnitsGroup",
                            },
                            localized({
                                variable: "localizedOptions",
                                tabContent: [
                                    {
                                        variable: "sentenceForShow",
                                        label: getI18nLocaleObject(namespaceList.pluginLocationSearchContainer, "sentenceForShow"),
                                        type: "text",
                                    },
                                    {
                                        variable: "sentenceForHide",
                                        label: getI18nLocaleObject(namespaceList.pluginLocationSearchContainer, "sentenceForHide"),
                                        type: "text",
                                    },
                                    {
                                        variable: "sentenceForShowSingular",
                                        label: getI18nLocaleObject(namespaceList.pluginLocationSearchContainer, "sentenceForShowSingular"),
                                        type: "text",
                                    },
                                    {
                                        variable: "sentenceForHideSingular",
                                        label: getI18nLocaleObject(namespaceList.pluginLocationSearchContainer, "sentenceForHideSingular"),
                                        type: "text",
                                    },
                                ],
                                visible: (options: WidgetOptions) => !!options.loadUnits,
                                groupName: "displayUnitsGroup",
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "presetOptions"),
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "descriptionForPresetOptions"),
                            },
                            amenityMultiSelect("amenities"),
                            multiSelectAccoKinds("accoKinds", getI18nLocaleObject(namespaceList.widgetTypeSearch, "accoKinds")),
                            multiSelectSpecials("specialCodes", getI18nLocaleObject(namespaceList.widgetUnitSearch, "specialCodes")),
                            regionsAutocomplete("regionId"),
                            {
                                variable: "minArrivalDate",
                                type: "date",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "minArrivalDate"),
                            },
                            {
                                variable: "maxArrivalDate",
                                type: "date",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "maxArrivalDate"),
                            },
                            resortAutocomplete("resortId"),
                            defaultStayAutoComplete("defaultStay"),
                            { ...stayHolidayPeriodDefAutocomplete("holidayCode") },
                            minCapacityField("minCapacity"),
                            localized({
                                variable: "localizedOptions",
                                tabContent: [
                                    {
                                        variable: "distributionChannelId",
                                        label: getI18nLocaleObject(namespaceList.widgetUnitSearch, "distributionChannel"),
                                        type: "lazyLoadAutoComplete",
                                        lazyLoadOptions: (page: number, searchQuery: string, id) => getDistributionChannelLazyLoadPage(page, searchQuery, id ? [id] : undefined, MxtsApi),
                                        placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "dcPlaceholder"),
                                    },
                                    {
                                        variable: "mobileDCId",
                                        label: getI18nLocaleObject(namespaceList.widgetUnitSearch, "distributionChannelMobile"),
                                        type: "lazyLoadAutoComplete",
                                        lazyLoadOptions: (page: number, searchQuery: string, id) => getDistributionChannelLazyLoadPage(page, searchQuery, id ? [id] : undefined, MxtsApi),
                                        placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "dcPlaceholder"),
                                    },
                                    {
                                        variable: "rateTypes",
                                        label: getI18nLocaleObject(namespaceList.admin, "rateTypes"),
                                        type: "multiselect",
                                        optionList: ({ item, locale }: { item?: any; locale?: string }) => (item && locale ? rateTypeOptions(MxtsApi, item, locale) : []),
                                        visible: (item?: LocalizeDcOptions) => !!item?.distributionChannelId,
                                    },
                                ],
                            }),
                            {
                                variable: "minGuestRating",
                                label: getI18nLocaleObject(namespaceList.widgetUnitSearch, "minGuestRating"),
                                type: "number",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "style"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            {
                                label: getI18nLocaleObject(namespaceList.pluginTypesearchContainer, "unitButtonIcon"),
                                variable: "unitButtonIcon",
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !!options.loadUnits,
                                groupName: "displayUnitsGroup",
                                groupTitle: i18nDisplayUnitsGroup,
                            },
                            {
                                variable: "iconPropertiesForLess",
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "iconForLess"),
                                type: "select",
                                visible: (options: WidgetOptions) => options.unitButtonIcon,
                                optionList: iconList,
                                groupName: "displayUnitsGroup",
                            },
                            {
                                variable: "iconPropertiesForLess",
                                type: "icons",
                                visible: (options: WidgetOptions) => options.unitButtonIcon,
                                groupName: "displayUnitsGroup",
                            },
                            {
                                variable: "iconPropertiesForMore",
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "iconForMore"),
                                type: "select",
                                visible: (options: WidgetOptions) => options.unitButtonIcon,
                                optionList: iconList,
                                groupName: "displayUnitsGroup",
                            },
                            {
                                variable: "iconPropertiesForMore",
                                type: "icons",
                                visible: (options: WidgetOptions) => options.unitButtonIcon,
                                groupName: "displayUnitsGroup",
                            },
                            {
                                variable: "iconColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconColor"),
                                type: "dual-color",
                                default: "default",
                                visible: (options: WidgetOptions) => options.unitButtonIcon,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "iconToRight"),
                                variable: "iconToRight",
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.unitButtonIcon,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "extra"),
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "extraInfoDescription"),
                            },
                            {
                                variable: "webContentId",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundWebcontent"),
                                type: "autocomplete",
                                refType: webcontentSpecWithoutPreview,
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundContentPlaceholder"),
                            },
                            {
                                variable: "templateId",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundTemplate"),
                                type: "autocomplete",
                                refType: templateSpec,
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundTemplatePlaceholder"),
                            },
                            {
                                variable: "sortOnArrivalDate",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "sortOnArrivalDate"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "displayBookingRestrictedAccoTypes",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "displayBookingRestrictedAccoTypes"),
                                default: false,
                                type: "checkbox",
                            },
                            localized({
                                variable: "localizedBookingIsRestrictedMsg",
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "bookingIsRestrictedMsg"),
                                        variable: "bookingIsRestrictedMsg",
                                        type: "text",
                                    },
                                ],
                                visible: (options: WidgetOptions) => !!options.displayBookingRestrictedAccoTypes,
                            }),
                        ],
                    ],
                },
                /* jscpd:ignore-end */
            ],
        },
    ],
};
/* jscpd:ignore-end */
export const typesearchContainerWidget: PageWidgetSpec<WidgetOptions> = {
    id: "typesearch-container-widget",
    type: "page",
    widgetGroup: WidgetGroup.DYNAMIC,
    name: getI18nLocaleObject(namespaceList.pluginTypesearchContainer, "typesearchContainerWidget"),
    description: getI18nLocaleObject(namespaceList.pluginTypesearchContainer, "typesearchContainerWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        typeOfDisplay: "",
        multipleLayouts: false,
        resultsPanelList: "",
        resultsPanelGrid: "",
        resultsPanelTablet: "",
        resultsPanelMobile: "",
        styleIds: [],
        showLoadMoreButton: false,
        defaultNumberOfTypes: undefined,
        nextNumberOfTypes: undefined,
        displayType: "displayColumns",
        unitPageUri: "",
        resultStyleSelector: ResultStyleSelector.DEFAULT,
        sortOnArrivalDate: false,
        showAmenitiesCount: false,
        unitButtonIcon: false,
        iconToRight: false,
        iconColor: "color-brand",
    }),
    async render(widget: Widget<WidgetOptions>, context: CMSProvidedProperties, sitemapPageLinkWidgetOptions, resultOptions) {
        const { resultsPanelList, resultsPanelGrid, resultsPanelTablet, resultsPanelMobile, styleIds } = widget.options;
        const typeLayoutList: Typesearch | null = resultsPanelList ? await context.cmsApi.typesearchApi.findById({ id: resultsPanelList }) : null;
        const typeLayoutGrid: Typesearch | null = resultsPanelGrid ? await context.cmsApi.typesearchApi.findById({ id: resultsPanelGrid }) : null;
        const typeLayoutTablet: Typesearch | null = resultsPanelTablet ? await context.cmsApi.typesearchApi.findById({ id: resultsPanelTablet }) : null;
        const typeLayoutMobile: Typesearch | null = resultsPanelMobile ? await context.cmsApi.typesearchApi.findById({ id: resultsPanelMobile }) : null;
        if (typeLayoutList === null && typeLayoutGrid === null && typeLayoutTablet === null && typeLayoutMobile === null) {
            throw new Error("No TypeLayout selected");
        }
        const classNames = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds as any[]);
        const childrenList = typeLayoutList ? await renderPageWidgets(typeLayoutList.root, context) : [];
        const childrenGrid = typeLayoutGrid ? await renderPageWidgets(typeLayoutGrid.root, context) : [];
        const childrenMobile = typeLayoutMobile ? await renderPageWidgets(typeLayoutMobile.root, context) : [];
        const childrenTablet = typeLayoutTablet ? await renderPageWidgets(typeLayoutTablet.root, context) : [];
        const children = (
            await Promise.all(
                widget.children.map((child) => {
                    const childSpec = child.spec;
                    if (isPageWidget(childSpec)) {
                        return childSpec
                            .render(child, context, undefined, {})
                            .then((element) => ({ element, options: child.options }))
                            .catch((err) => {
                                reportWidgetRenderError(widget, err, childSpec, context);
                                return null;
                            });
                    }
                    throw new TypeError("Expected child widgets to be page widgets");
                })
            )
        ).filter(Boolean) as Array<{ element: JSX.Element; options: WidgetOptions }>;

        let warmupState: any;
        if (isServerSide() && context.cacheStrategy === CacheStrategy.OPTIMIZED && (!resultOptions || Object.keys(resultOptions).length > 0)) {
            warmupState = await TypesearchContainerWidgetBase.warmupCache({
                classNames,
                options: widget.options,
                context,
                children,
                childrenList,
                childrenGrid,
                childrenMobile,
                childrenTablet,
                availabilityState: {},
                dynamicFilter: context.reduxStore.store.getState().dynamicFilter,
                dispatchAction: context.reduxStore.store.dispatch,
                amenities: undefined,
                userInterfaceState: {},
            });
            // warmup each result
            if (warmupState.mapSynchedAccommodationTypes) {
                const dynamicFilter = context.reduxStore.store.getState().dynamicFilter;
                const accommodationTypes = warmupState.mapSynchedAccommodationTypes || [];
                await Promise.all(
                    accommodationTypes.map(async (accommodationType: AccommodationType) => {
                        const accoProps: ResultOptions = {
                            useCrpProps: true,
                            accommodationType,
                            newRateTypeId: dynamicFilter.rateType?.rateTypeId,
                            unitBookUri: warmupState.unitBookUri,
                            key: warmupState.accommodationType ? warmupState.accommodationType.resourceId : generateRandomKey(),
                            amenityCodes: warmupState.amenityCodes || [],
                        };
                        await Promise.all([
                            typeLayoutList ? await renderPageWidgets(typeLayoutList.root, context, undefined, accoProps) : Promise.resolve([]),
                            typeLayoutGrid ? await renderPageWidgets(typeLayoutGrid.root, context, undefined, accoProps) : Promise.resolve([]),
                            typeLayoutMobile ? await renderPageWidgets(typeLayoutMobile.root, context, undefined, accoProps) : Promise.resolve([]),
                            typeLayoutTablet ? await renderPageWidgets(typeLayoutTablet.root, context, undefined, accoProps) : Promise.resolve([]),
                        ]);
                    })
                );
            }
        }
        return (
            <TypesearchContainerWidget
                classNames={classNames}
                options={widget.options}
                context={context}
                children={children}
                childrenList={childrenList}
                childrenGrid={childrenGrid}
                childrenMobile={childrenMobile}
                childrenTablet={childrenTablet}
                warmupState={warmupState}
            />
        );
    },
    async initDefaultFilter(widget, context, dispatcher): Promise<void> {
        await TypesearchContainerWidgetBase.initDefaultFilter({
            options: widget.options,
            context,
            dynamicFilter: context.reduxStore.store.getState().dynamicFilter,
            dispatchAction: dispatcher,
        } as any);
    },
};
