import { I18nLocaleObject, getI18nLocaleObject } from "../i18n";

import { InputSpecAuto } from "../form-specs";
import { MXTS } from "../utils/constants";
import { MxtsApi } from "@maxxton/cms-mxts-api";
import { SelectOption } from "../form-specs/formSpec.types";
import { getMxtsEnv } from "../plugins/mxts";
import { globalApiContext } from "../containers/CmsProvider";
import namespaceList from "../i18n/namespaceList";

function stayHolidayPeriodDefAutocomplete<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string, visible?: (options: E) => boolean): InputSpecAuto<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.widgetTypeSearch, "holidayCode"),
        type: "autocomplete",
        options: async (): Promise<Array<SelectOption<E[P]>>> => {
            const env = await getMxtsEnv(globalApiContext());
            const holidayStayPeriodDefs = await MxtsApi.stayPeriodDefs(env, { size: MXTS.MAX_RESULTS, type: "HOLIDAY" }).then((pagedResult) => pagedResult.content);
            const options = holidayStayPeriodDefs.map(({ stayPeriodDefId, code, name }) => ({
                label: `${name} (Id: ${stayPeriodDefId}, Code: ${code})`,
                value: (code as unknown) as E[P],
            }));
            return options;
        },
        visible,
    };
}

export default stayHolidayPeriodDefAutocomplete;
